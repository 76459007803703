import {
  mapActions, mapMutations,
} from 'vuex';
export default {
  methods: {
    ...mapMutations(['spinner']),
    ...mapActions('Collections', ['getCollectionsViews', 'getCollectionsSchema', 'setReadCollectionView']),
    async getDataForProductPreview() {
      const { selectedProduct: selected } = this;
      const selectedProductMapping = Array.isArray(selected) ? selected[selected.length - 1] : selected;
      const { collection: collectionId, id: rowId } = selectedProductMapping;
      this.spinner(true);
      await this.getCollectionsViews({
        collectionId,
      });
      await this.getCollectionsSchema({
        collectionId,
      });
      await this.setReadCollectionView({
        tableType: 'collection',
        tableId: collectionId,
        rowId,
        rowVersionId: '',
        compareVersion: '',
        showProductDialog: false,
      });
      this.spinner(false);
    },
  },
};
