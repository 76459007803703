<template>
  <div
    class="wrapper dropdown-wrapper dropdown-border-solo">
    <product-cell-field-text
      :value="value"
      :is-expanded-mode="isExpandedMode"
      :disabled-edit-mode="disabledEditMode"
      :product-hint="productHint"
      @emitChange="emitChange" />
    <app-dialog
      v-if="showAddIcon"
      v-model="dialog"
      :value.sync="dialog"
      width="1124"
      persistent
      content-class="v-dialog__form"
      :close-on-content-click="false">
      <v-card
        class="pa-0">
        <v-card-title>
          <div class="d-flex align-center">
            {{ columnTitle }}
          </div>
          <span
            class="sourcery__icon-wrapper black"
            @click="dialog = false">
            <v-icon
              color="white"
              size="15">
              mdi-close
            </v-icon>
          </span>
        </v-card-title>
        <v-card-text class="pa-0">
          <FieldProductAutocomplete
            :col="col"
            :created-product="createdProduct"
            :multiple="isMultipleField"
            :value="value"
            :dialog="dialog"
            class="mt-4"
            @change="selectProduct">
            <template
              v-if="useProductRefEqualCreateNewProduct"
              #appendOuterContent>
              <btn-create-product
                :loading-btn="showSpinner"
                @setStatusDialog="setStatusDialog" />
            </template>
          </FieldProductAutocomplete>
        </v-card-text>
      </v-card>
      <template
        v-if="!isExpandedMode"
        #activator="{ on }">
        <div @click="openWindow($event,on)">
          <v-icon
            color="green"
            class="v-dropdown__custom--add-icon mr-2 mb-3">
            mdi-plus-circle
          </v-icon>
        </div>
      </template>
    </app-dialog>
    <slot
      name="previewDialog"
      :selectedColumnOrder="selectedColumnOrder"
      :selectedRefFields="selectedRefFields"
      :selectRefFields="selectRefFields"
      :dialog="dialogForRefFields"
      :item="item"
      :columnTitle="columnTitle"
      :manageDialog="manageDialog" />
    <collections-create-product-dialog
      v-if="useProductRefEqualCreateNewProduct"
      :status-dialog.sync="statusDialogCreateProduct"
      :is-source-library-id="isSourceLibraryId"
      :is-status-dialog-from-state="false"
      :is-allow-comparison="false"
      :prop-library-id="sourceLibraryId"
      @savedProduct="savedProduct" />
  </div>
</template>
<script>
import {
  mapState, mapGetters, mapMutations,
} from 'vuex';
import EditModeChild from '@/mixins/EditModeChildComponent';
import GetDataForProductPreview from '@/mixins/GetDataForProductPreview';
import FieldProductAutocomplete from '../ProductCellFieldProductAutocomplete';
import productHeaders from '@/constants/productHeaders';
import ProductCellFieldText
  from '@/components/Product/ProductCell/ProductCellFieldProduct/ProductCellFieldText';
import CollectionsCreateProductDialog
  from '@/components/Collections/CollectionsCreateProductDialog';
import BtnCreateProduct
  from '@/components/Product/ProductCell/ProductCellFieldProduct/ProductCellFieldProductBasic/BtnCreateProduct';
export default {
  name: 'ProductCellFieldProductBasic',
  components: {
    BtnCreateProduct,
    CollectionsCreateProductDialog,
    ProductCellFieldText,
    FieldProductAutocomplete,
  },
  mixins: [EditModeChild, GetDataForProductPreview],
  props: {
    isExpandedMode: {
      type: Boolean,
      default: true,
    },
    item: {
      type: Object,
      required: true,
    },
    hasAccessEditor: {
      type: Boolean,
      default: true,
    },
    header: {
      type: Object,
      required: true,
    },
    disabledEditMode: {
      type: Boolean,
      default: false,
    },
    borderedCell: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogForRefFields: false,
      statusDialogCreateProduct: false,
      selectedProduct: null,
      createdProduct: {
      },
    };
  },
  computed: {
    ...mapState('ProjectDetailsTableSchedule', ['availReferenceFields']),
    ...mapState('ProjectDetails', ['detailProjectData']),
    ...mapGetters('UserProfile', ['isUnsuccessfulPayment']),
    ...mapGetters('Collections', ['selectedColumnOrder']),
    ...mapGetters('FeatureFlags', [
      'useProductRefEqualCreateNewProduct',
      'addTextNoteToEqualField',
    ]),
    ...mapState({
      showSpinner: state => state.showSpinner,
    }),
    ...mapGetters({
      getDefaultLibraryId: 'Libraries/getDefaultLibraryId',
      isVersionMode: 'ProjectVersions/isVersionMode',
    }),
    isSourceLibraryId() {
      return Boolean(this.detailProjectData?.sourceLibraryId);
    },
    sourceLibraryId() {
      return this.detailProjectData?.sourceLibraryId || this.getDefaultLibraryId;
    },
    selectedRefFields() {
      const fields = this.value?.fields;
      // return fields?.length ? fields : this.availReferenceFields;
      return fields?.length ? fields : this.selectedColumnOrder;
    },
    showAddIcon() {
      const { disabledEditMode, isVersionMode } = this;
      return !disabledEditMode && !isVersionMode && !this.isUnsuccessfulPayment;
    },
    productHint() {
      const { isMultipleField, isSourceLibraryId, header, addTextNoteToEqualField: flag } = this;
      if (isMultipleField && !flag) {
        return '';
      }
      const { hint, hintWithSourceLibrary } = header?.column || {
      };
      if (!isSourceLibraryId) return hint;
      return hintWithSourceLibrary;
    },
    columnTitle() {
      const { header, item } = this;
      let title = `${header?.value}`;
      const typeValue = item[productHeaders.TYPE_DESIGNATION];
      if (typeValue && typeValue !== '\b') {
        title = `${typeValue} - ${title}`;
      }
      return title;
    },
    isMultipleField() {
      return this.col !== 'Product Reference';
    },
    dialog: {
      get() {
        return this.editMode;
      },
      set(val) {
        this.setEditMode(val);
      },
    },
  },
  watch: {
    dialogForRefFields(val) {
      if (!val) {
        this.setProductToUpdate(null);
        this.setCollectionsSchema(null);
      }
    },
  },
  methods: {
    ...mapMutations('Collections', ['setProductToUpdate', 'setCollectionsSchema']),
    setStatusDialog(val) {
      this.statusDialogCreateProduct = val;
    },
    // it's method triggering for a watcher in child component
    savedProduct(val) {
      this.createdProduct = val;
    },
    openWindow(e, on) {
      if (!this.hasAccessEditor) {
        this.$emit('messagesNoHasAccessEditor');
      } else
        on.click(e);
    },
    async selectRefFields(data) {
      if (this.value?.id) {
        const response = await this.$openConfirm({
          text: 'This action will replace/overwrite all information contained in this schedule item - Do you wish to proceed?',
        });
        if (!response) {
          return;
        }
      }
      this.updateCell(this.selectedProduct, data);
    },
    manageDialog(val) {
      this.dialogForRefFields = val;
    },
    selectProduct(selected) {
      const { isMultipleField: multiple } = this;
      if (!multiple) {
        this.dialogForRefFields = true;
        this.selectedProduct = selected;
        this.getDataForProductPreview();
      } else {
        this.dialogForRefFields = true;
        this.selectedProduct = selected;
        this.getDataForProductPreview();
      }
    },
    updateCell(selected, fields) {
      const { isMultipleField: multiple } = this;
      const value = {
        [multiple ? 'values' : 'value']: !multiple ? JSON.stringify({
          id: selected.id,
          title: selected.title,
          fields,
          collectionID: selected.collection,
        }) : [...selected.map(({ id, title }) => JSON.stringify({
          id,
          title,
        }))],
      };
      this.dialog = false;
      this.dialogForRefFields = false;
      this.emitChange(value);
    },
    emitChange(value) {
      if (!this.hasAccessEditor) {
        this.$emit('messagesNoHasAccessEditor');
      } else
        this.$emit('change', value);
    },
  },
};
</script>
<style scoped lang="scss">
.v-dialog {
  overflow: hidden;
  .v-card {
    &__text {
      padding: 0 24px !important;
    }
  }
}
.dropdown-wrapper {
  &__hint-text {
    font-size: 10px;
    font-weight: 400;
  }
}
.v-dropdown__custom--add-icon {
  position: absolute;
  cursor: pointer;
  bottom: 0;
  right: 0;
}
.v-dropdown__custom--add-icon {
  border-radius: .25rem;
  background-clip: text;
}
.sticky-search {
  position: sticky;
  top: 0;
  z-index: 1;
}
.v-card {
  .dropdown-border-solo {
    line-height: 100%;
    padding: 0 5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 40px;
    background-color: var(--v-lightGrey-base);
  }
  .dropdown-border {
    padding-top: 16px;
    padding: 10px;
    min-height: 64px;
    border-radius: 48px;
    border: 1px solid black;
  }
}
.v-card {
  .v-dropdown {
    &__custom {
      &--add-icon {
        position: absolute;
        cursor: pointer;
        bottom: 21px;
        right: 20px;
      }
    }
  }
}
</style>
