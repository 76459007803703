<template>
  <div
    v-if="value && !Array.isArray(value)"
    class="d-flex product-reference-title">
    {{ value.title }}
    <v-icon
      v-if="value.id && !disabledEditMode"
      x-small
      class="ml-1"
      @click.stop="emitChange({
        value: '',
      })">
      mdi-close
    </v-icon>
  </div>
  <ol
    v-else-if="value && Array.isArray(value)">
    <li
      v-for="val in value"
      :key="val.id"
      class="mt-2">
      {{ val.title }}
      <v-icon
        v-if="!disabledEditMode"
        x-small
        class="ml-1"
        @click.stop="emitChange({
          values: value.filter(item => item.id !== val.id)
            .map(item => JSON.stringify(item)),
        })">
        mdi-close
      </v-icon>
    </li>
  </ol>
  <div
    v-else-if="canSeeInstructionNote"
    :class="{'dropdown-wrapper__hint-text': isExpandedMode}"
    class="dropdown-wrapper__hint">
    {{ productHint }}
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'ProductCellFieldText',
  props: {
    value: {
      type: [Object, Array],
      default: () => null,
    },
    disabledEditMode: {
      type: Boolean,
      default: false,
    },
    isExpandedMode: {
      type: Boolean,
      default: true,
    },
    productHint: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      canSeeInstructionNoteProductReference: 'UserRoles/canSeeInstructionNoteProductReference',
    }),
    canSeeInstructionNote() {
      const { canSeeInstructionNoteProductReference: flag, productHint: hint } = this;
      return flag && !!hint;
    },
  },
  methods: {
    emitChange(val) {
      this.$emit('emitChange', val);
    },
  },
};
</script>
<style scoped>
.product-reference-title {
  word-break: break-all;
}
</style>
