<template>
  <div>
    <v-text-field
      v-model="search"
      :placeholder="placeholderSearch"
      class="input-search"
      color="black"
      clearable
      flat
      outlined
      hide-details>
      <template #append-outer>
        <slot name="appendOuterContent" />
      </template>
    </v-text-field>
    <Results
      :items="filteredProducts"
      @addToSelected="addToSelected"
      @onIntersect="onIntersect" />
  </div>
</template>
<script>
import SearchProducts from '@/services/graphql/searching/searchProducts';
import productHeaders from '@/constants/productHeaders';
import {
  mapMutations, mapState, mapActions, mapGetters,
} from 'vuex';
import {
  debounce, uniqBy, cloneDeep,
} from 'lodash';
import { DEBOUNCE_TIME_FOR_SEARCHING } from '@/constants';
import AsyncSearching from '@/mixins/AsyncSearching';
import { v4 as uuid } from 'uuid';
import { parseGetRows } from '@/utils/manageGetRows';
import XRay from '@/utils/xRay/XRay';
import { SEARCH_PRODUCT_REF_V3 } from '@/constants/searching/searchingAsyncV2';
const xRay = new XRay();
export default {
  name: 'ProductCellFieldProductAutocomplete',
  components: {
    Results: () => import('./ProductCellFieldProductAutocompleteResults'),
  },
  mixins: [AsyncSearching],
  props: {
    col: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    value: {
      type: undefined,
      default: null,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    createdProduct: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      selected: null,
      search: '',
      isProductFromSearch: false,
      products: [],
      scrollID: null,
    };
  },
  computed: {
    ...mapState(['showSpinner']),
    ...mapState('StoredProducts', ['recentlyAccessedCollection']),
    ...mapState('ProjectDetails', ['detailProjectData']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapGetters({
      lastProductRendering: 'StoredProducts/lastProductRendering',
    }),
    valueIds() {
      const { value } = this;
      let valueArr = value && Array.isArray(value) ? value : [value];
      return valueArr?.map((e => e?.id)) || [];
    },
    isAccessPreloadingProducts() {
      return this.preloadingProducts.length;
    },
    preloadingProducts() {
      const {
        filteredLastEditedProducts: products,
        filteredRecentlyAccessedCollectionProducts: productCollections,
      } = this;
      return uniqBy([...products, ...productCollections], 'id');
    },
    filteredLastEditedProducts() {
      const { sourceLibraryId = '', valueIds } = this;
      return this.lastProductRendering.reduce((result, current) => {
        const { id = '' } = current || {
        };
        const isSourceLibraryId = current?.libraryId === sourceLibraryId;
        const res = this.parserProduct(current, isSourceLibraryId);
        return res && !valueIds.includes(id) ? [...result, res] : result;
      }, []);
    },
    filteredRecentlyAccessedCollectionProducts() {
      const { sourceLibraryId = '', valueIds } = this;
      const {
        products,
        collectionName,
        libraryId,
        collectionId: collection,
      } = this.recentlyAccessedCollection;
      if (!sourceLibraryId || sourceLibraryId === libraryId) {
        return products.reduce((result, current) => {
          const { id = '' } = current || {
          };
          const isSourceLibraryId = libraryId === sourceLibraryId;
          const res = this.parserProduct(current, isSourceLibraryId);
          return res && !valueIds.includes(id) ? [...result, {
            ...res, collectionName, collection,
          }] : result;
        }, []);
      }
      return [];
    },
    sourceLibraryId() {
      return this.detailProjectData?.sourceLibraryId || '';
    },
    lastProductId() {
      return this.products[this.products.length - 1]?.id;
    },
    filteredProducts() {
      const { value, valueIds } = this;
      if (value) {
        return this.products.filter(item => !valueIds.includes(item.id));
      }
      return this.products;
    },
    textPlaceholder() {
      return 'Find an existing Product record from Collections in the';
    },
    placeholderSearch() {
      if (this.col === 'Product Reference') {
        return this.sourceLibraryId
          ? this.textPlaceholder + ' pre-defined Library'
          : this.textPlaceholder + ' current Workspace';
      }
      return 'Find an existing record';
    },
  },
  watch: {
    createdProduct: {
      deep: true,
      handler(val) {
        this.parseNewProduct(cloneDeep(val));
      },
    },
    value: {
      handler(val) {
        this.selected = val;
      },
      immediate: true,
    },
    dialog: {
      handler(val) {
        const {
          search,
          searchNewSet,
          isAccessPreloadingProducts,
          preloadingProducts,
        } = this;
        if (!val) {
          this.onCloseClear();
          return;
        }
        if (search) {
          this.search = '';
        } else {
          if (isAccessPreloadingProducts) {
            this.isProductFromSearch = false;
            this.products = preloadingProducts;
          } else
            searchNewSet();
        }
      },
      immediate: true,
    },
    search: debounce(function () {
      this.searchNewSet();
    }, DEBOUNCE_TIME_FOR_SEARCHING),
  },
  beforeDestroy() {
    this.onCloseClear();
  },
  methods: {
    ...mapMutations(['spinner']),
    ...mapActions(['handleError']),
    parseNewProduct(product) {
      const {
        fields = [], collectionId = '', collectionName
        = '', libraryId = '',
      } = product || {
      };
      const parsedProduct = parseGetRows(fields);
      const objProduct = {
        collectionId,
        collectionName,
        libraryId,
        ...parsedProduct,
      };
      const mappedProduct = this.parserProduct(objProduct, true);
      this.addNewProduct(mappedProduct);
      this.addToSelected(mappedProduct);
    },
    addNewProduct(product) {
      const { isProductFromSearch, products, preloadingProducts } = this;
      if (isProductFromSearch) this.products = [product, ...products];
      else this.products = preloadingProducts;
    },
    parserProduct(product, isSourceLibraryId) {
      const { IMAGE, PRODUCT_TYPE, MODEL, MANUFACTURER, DESCRIPTION } = productHeaders;
      const { VERIFIED, CHILD_VERIFIED, VERIFIED_PAGE_ID, VERIFIED_PAGE_NAME } = productHeaders;
      const { sourceLibraryId = '' } = this;
      const {
        collectionName = '',
        id = '',
        [IMAGE]: attachment = [],
        [PRODUCT_TYPE]: productType = [],
        [MODEL]: model = '',
        [MANUFACTURER]: manufacturer = '',
        [DESCRIPTION]: shortDescription = '',
        [VERIFIED]: isVerified = false,
        [CHILD_VERIFIED]: childVerified = '',
        [VERIFIED_PAGE_ID]: verifiedPageId = '',
        [VERIFIED_PAGE_NAME]: verifiedPageName = '',
        collectionId: collection = '',
      } = product || {
      };
      const isPassProductSourceLibrary = !sourceLibraryId || isSourceLibraryId;
      const parsedProduct = {
        id,
        attachment,
        productType,
        model,
        collectionName,
        manufacturer,
        shortDescription,
        collection,
        isVerified,
        childVerified,
        verifiedPageId,
        verifiedPageName,
      };
      return !isPassProductSourceLibrary ? null : product.hasOwnProperty('SK') ? parsedProduct : product;
    },
    emitChange() {
      this.$emit('change', this.selected);
      this.selected = null;
    },
    addToSelected({ id, manufacturer, model, collection } = {
    }) {
      const { multiple } = this;
      const item = {
        id,
        title: `${manufacturer}, ${model}`,
        ...(collection && {
          collection,
        }),
      };
      if (multiple) {
        this.selected = this.selected ? [...this.selected, item] : [item];
      } else {
        this.selected = item;
      }
      this.emitChange();
    },
    searchNewSet() {
      this.scrollID = null;
      this.isProductFromSearch = true;
      this.products = [];
      this.searchProductRef();
    },
    onCloseClear() {
      this.unsubscribe();
      this.spinner(false);
    },
    async searchProductRef() {
      this.spinner(true);
      try {
        const LIMIT = 10;
        this.currentRequestId = uuid();
        const { search: query, activeWorkspaceId: workspaceId, scrollID } = this;
        const jsonForRequest = {
          ...(query && {
            query,
          }),
          projectId: this.$route.params.id,
          limit: LIMIT,
          scrollID,
          workspaceId,
          requestId: this.currentRequestId,
        };
        this.currentParseMethod = this.compareSearchMatches;
        await this.asyncSearchingSubscription({
          callback: this.setSearchResult,
        });
        xRay.startTimeTracker(SEARCH_PRODUCT_REF_V3);
        await SearchProducts['asyncSearchProductRefV3'](jsonForRequest);
      } catch (err) {
        this.spinner(false);
        this.handleError(err);
      }
    },
    compareSearchMatches({ data, searchString } = {
    }) {
      if ((searchString || this.search) && searchString !== this.search) {
        return;
      }
      this.setSearchResult({
        data,
      });
    },
    setSearchResult({ data } = {
    }) {
      this.spinner(false);
      const { data: dataProducts, scrollID, queryName } = data || {
      };
      xRay.stopTimeTracker(queryName);
      xRay.sendSegmentsToAws();
      this.products = this.scrollID ? [...this.products, ...dataProducts] : dataProducts;
      this.products = this.lodash.uniqBy(this.products, 'id');
      this.scrollID = scrollID;
    },
    onIntersect({ entries, isIntersecting } = {
    }) {
      if (isIntersecting) {
        const productId = entries[0].target.id;
        if (this.scrollID && productId === this.lastProductId) {
          this.searchProductRef();
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
.input-search {
  align-items: center;
  ::v-deep .v-input__append-outer {
    margin: 0 0 0 10px !important;
  }
}
.v-select-list {
  max-height: 50vh !important;
}
</style>
