<template>
  <v-btn
    :loading="loadingBtn"
    color="blue"
    class="white--text elevation-0"
    @click="createNewProduct">
    CREATE PRODUCT
  </v-btn>
</template>
<script>
import { mapMutations } from 'vuex';
import { TYPE_CREATE } from '@/constants';
export default {
  name: 'BtnCreateProduct',
  props: {
    loadingBtn: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapMutations({
      changeProductModalVariant: 'Collections/changeProductModalVariant',
    }),
    createNewProduct() {
      this.changeProductModalVariant(TYPE_CREATE);
      this.$emit('setStatusDialog', true);
    },
  },
};
</script>
<style scoped>
</style>
